<template>
  <div class="">
    <!-- Header -->
    <div class="header py-5">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-dark">
                <img src="img/logo.png" height="100px" />
              </h1>
              <p class="text-lead text-dark">Sua vida financeira com agilidade e segurança.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Para nós, vc não é um número. Escolha vc o seu nome de <b>@usuario</b></small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(submit)">
                  <base-input alternative
                              class="mb-3"
                              name="Usuário"
                              :rules="{required: true, min: 4, max: 50, regex: /^[a-z0-9]+(\.?[a-z0-9])+$/}"
                              :oninput="usernameFormalize()"
                              prepend-icon="fas fa-at"
                              placeholder="Nome de Usuário"
                              v-model="username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Senha"
                              :rules="{required: true, min: 6, max: 255}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Senha"
                              v-model="password">
                  </base-input>
                  <base-input alternative
                              class="mb-3"
                              name="Confirmar Senha"
                              :rules="{required: true, min: 6, max: 255}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Confirmar Senha"
                              v-model="confirm">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="E-mail"
                              :rules="{required: true, min: 6, max: 255}"
                              prepend-icon="ni ni-email-83"
                              placeholder="E-mail"
                              v-model="email">
                  </base-input>

                  <base-checkbox v-model="acceptTerms" :rules="{required: true}">
                    Eu aceito os <a href="https://web.olimpopay.com.br/docs/pdf/TERMOS_REDFOX_1.0.pdf" target="_blank">termos e condições</a>.
                  </base-checkbox>

                  <vue-recaptcha
                    ref="recaptcha"
                    :loadRecaptchaScript="true"
                    badge="bottomleft"
                    size="invisible"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    sitekey="6LcL_7kZAAAAAKvH8isNe9m69iPxtOKkTVovNcrX">
                  </vue-recaptcha>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">CADASTRAR</base-button>
                  </div>
                </form>
                </validation-observer>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/login" class="text-primary"><small>Voltar para o login</small></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha'
  import {postWs, signup} from '../../ws.service'

  export default {
    components: {
      VueRecaptcha
    },
    data() {
      return {
        codeRecommendation: '',
        username: "",
        password: "",
        confirm: "",
        email: "",
        acceptTerms: false,
        showSubmit: true,
      };
    },
    created() {
      this.createData()
    },
    mounted() {
      if(localStorage.getItem('isAuth') == 'true') {
        this.$router.push('/dashboard')
      }
      if (window.location.href.includes('olimpopay.com.br')
        && location.protocol != 'https:') {
        location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
        return
      }
      if (this.$route.query.fail == 1) {
        this.$swal({
          title: 'Serviço temporariamente indisponível!',
          text: 'Tente novamente mais tarde.',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill'
          },
          timer: 5000,
          icon: 'error'
        })
      } else if (this.$route.query.msg == 1) {
        postWs("/auth/validate-email", false,
          {hash: this.$route.query.code}, null,
          () => {
            this.$swal({
              title: 'Sucesso!',
              text: 'E-mail confirmado! Agora é só acessar utilizando o usuário e senha cadastrados.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              icon: 'success'
            })
          }, () => {
          })
      }
    },
    methods: {
      createData(){
        this.codeRecommendation = this.$route.query.code
      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      submit() {
        if (!this.acceptTerms) {
          this.$swal({
            title: "Aviso",
            text: 'Você deve aceitar os Termos e Condições antes de prosseguir.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 5000,
            icon: 'warning'
          })
          return
        }
        this.showSubmit = false
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified(recaptchaToken) {
        signup(this.username, this.password, this.email, recaptchaToken, this.successSignup,
          this.fail, this.codeRecommendation)
      },
      successSignup(response) {
        if (response.data == 'OK') {
          this.$swal({
            title: "Sucesso!",
            text: 'Foi enviado um e-mail de confirmação para você. Acesse seu e-mail e clique no botão \'Confirmar e-mail\'.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-success btn-fill'
            },
            icon: 'success'
          })
          this.$router.push('/login')
        } else {
          this.$swal({
            title: "Ops...",
            text: response.data == 'EMAIL_USED' ? 'E-mail já cadastrado!' : 'Usuário em uso, favor escolher outro.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 5000,
            icon: 'warning'
          })
          this.showSubmit = true
        }
      },
      fail(error) {
        if (error.response.status == 400) {
          this.$swal({
            title: 'Falha!',
            text: 'Recaptcha inválido.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger btn-fill'
            },
            icon: 'error'
          })
        } else {
          this.$swal({
            title: 'Serviço temporariamente indisponível!',
            text: 'Tente novamente mais tarde.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger btn-fill'
            },
            icon: 'error'
          })
        }

        this.showSubmit = true
      },
      onCaptchaExpired() {
        this.$refs.recaptcha.reset()
      },
      usernameFormalize() {
        this.username = this.username.replace(' ', '').toLowerCase()
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  };
</script>
